
.image-stream {
  margin-left: 1rem;
}
.img-stream {
  width: 90%;
  height: 100%;
  border-radius: 20px;
}
.caption{
    padding: 10px 20px;
    font-size: 14px;
}
.caption .name{
    font-weight: 600;
}

.caption .download{
    color: var(--gray-text-color);
}
