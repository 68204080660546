.LiteCard {
    padding: calc(var(--padding-primary)/2);
    border: 1px solid var(--dark-gray-color);
    border-radius: var(--radius-primary);
}

.LiteCard:hover {
    transform: translateY(-10px);
    border-color: var(--light-color);
    transition: .5s;
}

.LiteCard .icon img {
    border-radius: 50%;
    margin-bottom: calc(var(--padding-primary)/3);
}

.LiteCard .text h5 {
    font-weight: var(--bold-weight);
}

.LiteCard .text p {
    font-size: 15px;
    color: var(--gray-text-color);
    margin-top: 10px;
    line-height: 1.8;
}