.my-nav {
    min-height: 100px;
}

.active {
    color: var(--primary-color) !important;
}

.my-nav-list {
    align-items: center;
    gap: 30px;
}

.profile-img {
    border-radius: 50%;
    margin-left: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}